import React from 'react';
import { bool } from 'prop-types';
import { StyledMenu } from './Menu.styled';

const Menu = ({ open, ...props }) => {
  
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <div tabIndex={tabIndex}>
        <form method="POST" action="/create-portal-session">
          <button type="submit" className="trickybutton">
            <div className="billjackson">Billing</div>
          </button>
        </form>
      </div>
      <a href="mailto:music@liontheory.com" tabIndex={tabIndex}>
        Support
        </a>
      <a href="/logout" tabIndex={tabIndex}>
        Logout
        </a>
    </StyledMenu>
  )
}

Menu.propTypes = {
  open: bool.isRequired,
}

export default Menu;
