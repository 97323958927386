import './App.css';
import './quill.snow.css';
import React from 'react';
import { useState, useRef } from 'react';
//import { useOnClickOutside } from './hooks';
import ReactTooltip from "react-tooltip";
import lugo from './lugo.png';
import Info from './Info.js';
import Play from './Play.js';
import Stop from './Stop.js';
import Check from './Check.js';
import BigPlay from './BigPlay.js';
import BigStop from './BigStop.js';
import { Burger, Menu } from './components';

import { ThemeProvider } from 'styled-components';
import { useOnClickOutside } from './hooks';
import { GlobalStyles } from './global';
import { theme } from './theme';

class LevelSelect extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="laber">Level</div>
        <select id="level" onChange={this.props.levelChange} className="topselect">
          <option value="FSMTA_L1">Level 1</option>
          <option value="FSMTA_L2">Level 2</option>
          <option value="FSMTA_L3">Level 3</option>
          <option value="FSMTA_L4">Level 4</option>
          <option value="FSMTA_L5">Level 5</option>
          <option value="FSMTA_L6">Level 6</option>
        </select>
      </div>
    )
  }
}

class QTypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.levelShow = this.levelShow.bind(this);
  }

  levelShow() {
    var ary = [];
    switch(this.props.level) {
      case 'FSMTA_L1':
        var names = ['RHYTHM_ID','RHYTHM_SAMEDIFFERENT','MELODY_DIRECTION','MELODY_SAMEDIFFERENT','INTERVAL_ID','MELODY_FASTSLOW','MELODY_FORTEPIANO','MELODY_SCALEARPEGGIO','MELODY_STACCATOLEGATO'];
        var texts = ['Rhythm ID','Rhythm Same/Different','Melody Direction','Melody Same/Different','Interval ID','Melody Fast/Slow','Melody Forte/Piano','Melody Scale/Arpeggio','Melody Staccato/Legato'];
        break;
      case 'FSMTA_L2':
        var names = ['RHYTHM_ID','MELODY_ID','METER_ID','MELODY_RITARDANDO','MELODY_MAJORMINOR','INTERVAL_ID','SCALE_MAJORMINOR','DYNAMIC_ID','TOUCH_ID'];
        var texts = ['Rhythm ID','Melody ID','Meter ID','Melody Ritardando','Melody Major/minor','Interval ID','Scale Major/minor','Dynamic ID','Touch ID'];
        break;
      case 'FSMTA_L3':
        var names = ['RHYTHMIC_DICTATION','RHYTHM_ID','MELODY_ID','SCALE_ID','INTERVAL_ID','CHORD_GROUP_ID','DYNAMIC_ID','TOUCH_ID'];
        var texts = ['Rhythmic Dictation','Rhythm ID','Melody ID','Scale ID','Interval ID','Chord Group ID','Dynamic ID','Touch ID'];
        break;
      case 'FSMTA_L4':
        var names = ['RHYTHMIC_DICTATION','RHYTHM_ID','MELODY_ID','SCALE_ID','INTERVAL_ID','CHORD_GROUP_ID','ACCENT_ID'];
        var texts = ['Rhythmic Dictation','Rhythm ID','Melody ID','Scale ID','Interval ID','Chord Group ID','Accent ID'];
        break;
      case 'FSMTA_L5':
        var names = ['RHYTHMIC_DICTATION','RHYTHM_ID','MELODY_ID','SCALE_ID','INTERVAL_ID','CHORD_GROUP_ID','CHORD_PROGRESSION_ID','METER_ID'];
        var texts = ['Rhythmic Dictation','Rhythm ID','Melody ID','Scale ID','Interval ID','Chord Group ID','Chord Progression ID','Meter ID'];
        break;
      case 'FSMTA_L6':
        var names = ['MELODIC_DICTATION','RHYTHM_ID','MELODY_ID','SCALE_ID','INTERVAL_ID','CHORD_ID','CHORD_PROGRESSION_ID'];
        var texts = ['Dictation','Rhythm ID','Melody ID','Scale ID','Interval ID','Chord ID','Chord Progression ID'];
        break;
      case 'FSMTA_L7':
        var names = ['MELODIC_DICTATION','RHYTHM_ID','MELODY_ID','SCALE_ID','INTERVAL_ID','CHORD_ID','CHORD_PROGRESSION_ID'];
        var texts = ['Dictation','Rhythm ID','Melody ID','Scale ID','Interval ID','Chord ID','Chord Progression ID'];
        break;
      case 'FSMTA_L8':
        var names = ['MELODIC_DICTATION','MELODY_ID','TRITONE_RESOLUTION','HISTORICAL_PERIOD_ID','SCALE_ID','CHORD_ID','CHORD_PROGRESSION_ID','CADENCE_ID'];
        var texts = ['Dictation','Melody ID','Tritone Resolution','Historical Period ID','Scale ID','Chord ID','Chord Progression ID','Cadence ID'];
        break;
      default:
        break;
    }
    for (var i=0; i<names.length; i++) {
      ary.push(<option value={names[i]} key={i}>{texts[i]}</option>)
    }
    return ary;
  }

  render() {
    return (
      <div>
        <div className="laber">Topic</div>
        <select value={this.props.qtype} onChange={this.props.qtypeChange} id="qtype" className="topselect">
          {this.levelShow()}
        </select>
      </div>
    )
  }
}

class TopBar extends React.Component {
  constructor(props) {
    super(props);

    this.onQtypeChange = this.onQtypeChange.bind(this);
    this.onLevelChange = this.onLevelChange.bind(this);

    this.submit = this.submit.bind(this);
    this.retry = this.retry.bind(this);
    this.next = this.next.bind(this);

    this.state = {currently:'doing'};
  }

  next() {
    this.setState({currently:'doing'});
    this.props.next();
  }

  submit() {
    this.setState({currently:'viewing'});
    this.props.submit();
  }

  retry() {
    this.setState({currently:'doing'});
    this.props.retry();
  }

  submitRetry() {
    switch(this.state.currently) {
      case 'doing':
        return <button onClick={this.submit}>Submit</button>;
        break;
      case 'viewing':
        return <button onClick={this.retry}>Retry</button>;
        break;
    }
  }

  onQtypeChange(event) {
    this.props.qHandler('qtype',event.target.value);
  }

  onLevelChange(event) {
    this.props.bubbleState('level',event.target.value);
  }

  componentDidMount() {
    //go get levels for their subscription
    //beginner, intermediate, advanced
  }

  render() {
    return (
      <div className="topbar">
        <div className="burgerholder">
          <Hamburger/>
        </div>
        <div className="topbuts">
          {this.submitRetry()}
          <button onClick={this.next}>Next</button>
        </div>
        <div className="topops">
          <LevelSelect levelChange={this.onLevelChange}/>
          <QTypeSelect level={this.props.level} qtype={this.props.qtype} qtypeChange={this.onQtypeChange}/>
        </div>
      </div>
    )
  }
}

function Hamburger() {
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  //useOnClickOutside(node, () => setOpen(false));

  
  return (
    <ThemeProvider theme={theme}>
      <>
        
        <div ref={node}>
          
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
          
        </div>
        
      </>
    </ThemeProvider>
  )
}

class PlayDictationControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {playing:false,beating:false}
    this.playStop = this.playStop.bind(this);
    this.toggleRec = this.toggleRec.bind(this);
    this.playRec = this.playRec.bind(this);
    this.stopRec = this.stopRec.bind(this);

    this.resetRec = this.resetRec.bind(this);

    this.bigSmall = this.bigSmall.bind(this);
    this.bigPlayStop = this.bigPlayStop.bind(this);

    this.playBing = this.playBing.bind(this);
    this.playBock = this.playBock.bind(this);
    this.stopBing = this.stopBing.bind(this);
    this.stopBock = this.stopBock.bind(this);

    this.setupControls = this.setupControls.bind(this);

    new howlObj(this,'Bing');
    new howlObj(this,'Bock');
  }

  setupControls(obj,tsig,compound) {
    for (var p in obj) {
      new soundObj(this,obj,p);
    }
    this.tsig = tsig;
    this.compound = compound;
  }

  resetRec() {
    this.setState({playing:false,beating:false});
    this.stopRec();
    this.stopBing();
    this.stopBock();
  }

  toggleRec() {
    if (this.state.beating) return;
    switch(this.state.playing) {
      case true:
        this.setState({playing:false});
        this.stopRec();
        break;
      case false:
        this.setState({playing:true});
        this.playRec();
        break;
    }
  }

  playBing() {
    this.Bing.play();
  }

  stopBing() {
    this.Bing.stop();
  }

  playBock() {
    this.Bock.play();
  }

  stopBock() {
    this.Bock.stop();
  }

  playRec() {
    //compound meter "triples" the tempo
    var factor = 1;
    if (this.compound) factor = 0.5;

    //beat time
    var beatTime = 1000*factor;

    //play recording
    this.timeouts = [];

    //wait
    this.playBing();

    var wait = this.tsig*beatTime;
    for (var i=1; i<this.tsig; i++) {
      this.timeouts.push(setTimeout(function(){
        dis.playBock();
      },i*beatTime))
    }

    var dis = this;
    this.timeouts.push(setTimeout(
      function() {
        dis.Rec.play();
      }, wait
    ))
    //this.Rec.play();
  }

  stopRec() {
    //stop recording
    var touts = this.timeouts;
    if (touts) {
      for (var i=0; i<touts.length; i++)
        clearTimeout(touts[i]);
    }
    this.stopBing();
    this.stopBock();
    this.Rec.stop();
  }

  playStop() {
    switch(this.state.playing) {
      case true:
          return <Stop/>;
          break;
      case false:
          return <Play/>;
          break;
    }
  }

  bigPlayStop() {
    switch(this.state.playing) {
      case true:
          return <BigStop/>;
          break;
      case false:
          return <BigPlay/>;
          break;
    }
  }

  bigSmall() {
    if (this.props.size=="big") {
      return (
        <div className="buttflex">
          <div className="playbtn" onClick={this.toggleRec}>{this.bigPlayStop()}</div>
        </div>
      )
    } else {
      return (
        <div className="buttflex">
          <div className="playbtn" onClick={this.toggleRec}>{this.playStop()}</div>
        </div>
      )
    }
  }

  render() {
    return <div>{this.bigSmall()}</div>
  }
}

class PlayControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {playing:false,beating:false}
    this.playStop = this.playStop.bind(this);
    this.toggleRec = this.toggleRec.bind(this);
    this.playRec = this.playRec.bind(this);
    this.stopRec = this.stopRec.bind(this);
    this.resetRec = this.resetRec.bind(this);

    this.bigSmall = this.bigSmall.bind(this);
    this.bigPlayStop = this.bigPlayStop.bind(this);

    this.setupControls = this.setupControls.bind(this);
  }

  resetRec() {
    this.setState({playing:false});
    this.stopRec();
  }

  setupControls(obj) {
    for (var p in obj) {
      new soundObj(this,obj,p);
    }
  }

  toggleRec() {
    if (this.state.beating) return;
    switch(this.state.playing) {
      case true:
        this.setState({playing:false});
        this.stopRec();
        break;
      case false:
        this.setState({playing:true});
        this.playRec();
        break;
    }
  }

  playRec() {
    //play recording
    this.Rec.play();
  }

  stopRec() {
    //stop recording
    this.Rec.stop();
  }

  playStop() {
    switch(this.state.playing) {
      case true:
          return <Stop/>;
          break;
      case false:
          return <Play/>;
          break;
    }
  }

  bigPlayStop() {
    switch(this.state.playing) {
      case true:
          return <BigStop/>;
          break;
      case false:
          return <BigPlay/>;
          break;
    }
  }

  bigSmall() {
    if (this.props.size=="big") {
      return (
        <div className="buttflex">
          <div className="playbtn" onClick={this.toggleRec}>{this.bigPlayStop()}</div>
        </div>
      )
    } else {
      return (
        <div className="buttflex">
          <div className="playbtn" onClick={this.toggleRec}>{this.playStop()}</div>
        </div>
      )
    }
  }

  render() {
    return <div>{this.bigSmall()}</div>
  }
}

function soundObj(dis,obj,p) {
  dis[p] = new window.Howl({
    src: ["https://liontheory-eartrainer.s3.us-east-2.amazonaws.com/"+obj[p]]
  });
  dis[p].on('end', function(){
    dis['toggle'+p]();
  });
}

function howlObj(dis,path) {
  var howl = new window.Howl({
    src: ["https://liontheory-eartrainer.s3.us-east-2.amazonaws.com/"+path+'.mp3']
  });
  howl.on('end', function(){
    dis['stop'+path]();
  })
  dis[path] = howl;
}

class MultChoiceTripleRec extends React.Component {
  constructor(props) {
    super(props);
    this.askQuestion = this.askQuestion.bind(this);
    this.answerChoices = this.answerChoices.bind(this);
    this.state = {ops:[],text:null,answer:null,guess:null,guessWrong:null}

    this.next = this.next.bind(this);

    this.pick = this.pick.bind(this);
    this.submit = this.submit.bind(this);

    this.resetOptions = this.resetOptions.bind(this);

    this.playControls = React.createRef();
    this.playControls2 = React.createRef();
    this.playControls3 = React.createRef();

    var letters = ['A','B','C','D'];
    for (var i=0; i<letters.length; i++)
      this[letters[i]] = React.createRef();
  }

  submit() {
    if (!this.state.guess) {
      this.props.topbarRetry();
      return;
    }
    if (this.state.guess==this.state.answer) {
      this.props.correct(true);
      this.setState({guess:null,guessWrong:null});
      //this.resetOptions();
    } else {
      //incorrect
      this.setState({guessWrong:true});
    }
    this.playControls.current.resetRec();
    this.playControls2.current.resetRec();
    this.playControls3.current.resetRec();
  }

  resetOptions() {
    var letters = ['A','B','C','D'];
    for (var i=0; i<this.state.ops.length; i++)
      if (this[letters[i]])
        this[letters[i]].current.setlook('normal');
  }

  retry() {
    this.setState({guess:null,guessWrong:null});
    //reset all buttons
    //this.resetOptions();
  }

  pick(val) {
    //this.resetOptions();
    this.setState({guess:val});
  }

  next() {
    this.setState({guess:null,guessWrong:null});
    this.resetOptions();
    this.playControls.current.resetRec();
    this.playControls2.current.resetRec();
    this.playControls3.current.resetRec();
    this.askQuestion();
  }

  componentDidMount() {
    this.askQuestion();
  }

  askQuestion() {
    //load recording here
    //call server for multiple choice question
    //include level and qtype in request
    //Ex. call level 6 scale_id
    //returns options, right answer, and url for recording
    var dis = this;
    var cb = function(txt) {
      var obj = JSON.parse(txt);
      dis.setState({ops:obj.ops,answer:obj.answer,text:obj.text});
      var rbj = {Rec:obj.A+'.mp3'};
      dis.playControls.current.setupControls(rbj);
      var rbj2 = {Rec:obj.B+'.mp3'};
      dis.playControls2.current.setupControls(rbj2);
      var rbj3 = {Rec:obj.C+'.mp3'};
      dis.playControls3.current.setupControls(rbj3);
    }
    xhrGET('/multchoice?level='+this.props.level+'&qtype='+this.props.type,cb);
  }

  answerChoices() {
    var ops = this.state.ops;
    var ary = [];
    var letters = ['A','B','C','D'];
    for (var i=0; i<ops.length; i++) {
      ary.push(
        <div className="answerchoice" key={i}>
          <AnswerChoice choice={letters[i]} ref={this[letters[i]]} info={this.state} pick={this.pick}/><div className="multchoice">{ops[i]}</div>
        </div>
      )
    }
    return ary;
  }

  render() {
    return (
      <div className="rmdictation">
        <div className="qtext">{this.state.text}</div>
        <div><PlayControls size="big" ref={this.playControls}/></div>
        <div><PlayControls size="big" ref={this.playControls2}/></div>
        <div><PlayControls size="big" ref={this.playControls3}/></div>
        <div className="answerchoices">
          {this.answerChoices()}
        </div>
      </div>  
    )
  }
}

class MultChoiceDoubleRec extends React.Component {
  constructor(props) {
    super(props);
    this.askQuestion = this.askQuestion.bind(this);
    this.answerChoices = this.answerChoices.bind(this);
    this.state = {ops:[],text:null,answer:null,guess:null,guessWrong:null}

    this.next = this.next.bind(this);

    this.pick = this.pick.bind(this);
    this.submit = this.submit.bind(this);

    this.resetOptions = this.resetOptions.bind(this);

    this.playControls = React.createRef();
    this.playControls2 = React.createRef();

    var letters = ['A','B','C','D'];
    for (var i=0; i<letters.length; i++)
      this[letters[i]] = React.createRef();
  }

  submit() {
    if (!this.state.guess) {
      this.props.topbarRetry();
      return;
    }
    if (this.state.guess==this.state.answer) {
      this.props.correct(true);
      this.setState({guess:null,guessWrong:null});
      //this.resetOptions();
    } else {
      //incorrect
      this.setState({guessWrong:true});
    }
    this.playControls.current.resetRec();
    this.playControls2.current.resetRec();
  }

  resetOptions() {
    var letters = ['A','B','C','D'];
    for (var i=0; i<this.state.ops.length; i++)
      if (this[letters[i]])
        this[letters[i]].current.setlook('normal');
  }

  retry() {
    this.setState({guess:null,guessWrong:null});
    //reset all buttons
    //this.resetOptions();
  }

  pick(val) {
    //this.resetOptions();
    this.setState({guess:val});
  }

  next() {
    this.setState({guess:null,guessWrong:null});
    this.resetOptions();
    this.playControls.current.resetRec();
    this.playControls2.current.resetRec();
    this.askQuestion();
  }

  componentDidMount() {
    this.askQuestion();
  }

  askQuestion() {
    //load recording here
    //call server for multiple choice question
    //include level and qtype in request
    //Ex. call level 6 scale_id
    //returns options, right answer, and url for recording
    var dis = this;
    var cb = function(txt) {
      var obj = JSON.parse(txt);
      dis.setState({ops:obj.ops,answer:obj.answer,text:obj.text});
      var rbj = {Rec:obj.recID+'.mp3'};
      dis.playControls.current.setupControls(rbj);
      var rbj2 = {Rec:obj.recID2+'.mp3'};
      dis.playControls2.current.setupControls(rbj2);
    }
    xhrGET('/multchoice?level='+this.props.level+'&qtype='+this.props.type,cb);
  }

  answerChoices() {
    var ops = this.state.ops;
    var ary = [];
    var letters = ['A','B','C','D'];
    for (var i=0; i<ops.length; i++) {
      ary.push(
        <div className="answerchoice" key={i}>
          <AnswerChoice choice={letters[i]} ref={this[letters[i]]} info={this.state} pick={this.pick}/><div className="multchoice">{ops[i]}</div>
        </div>
      )
    }
    return ary;
  }

  render() {
    return (
      <div className="rmdictation">
        <div className="qtext">{this.state.text}</div>
        <div><PlayControls size="big" ref={this.playControls}/></div>
        <div><PlayControls size="big" ref={this.playControls2}/></div>
        <div className="answerchoices">
          {this.answerChoices()}
        </div>
      </div>  
    )
  }
}

class MultChoice extends React.Component {
  constructor(props) {
    super(props);
    this.askQuestion = this.askQuestion.bind(this);
    this.answerChoices = this.answerChoices.bind(this);
    this.state = {ops:[],text:null,answer:null,guess:null,guessWrong:null}

    this.next = this.next.bind(this);

    this.pick = this.pick.bind(this);
    this.submit = this.submit.bind(this);

    this.resetOptions = this.resetOptions.bind(this);

    this.playControls = React.createRef();

    var letters = ['A','B','C','D'];
    for (var i=0; i<letters.length; i++)
      this[letters[i]] = React.createRef();
  }

  submit() {
    if (!this.state.guess) {
      this.props.topbarRetry();
      return;
    }
    if (this.state.guess==this.state.answer) {
      this.props.correct(true);
      this.setState({guess:null,guessWrong:null});
      //this.resetOptions();
    } else {
      //incorrect
      this.setState({guessWrong:true});
    }
    this.playControls.current.resetRec();
  }

  resetOptions() {
    var letters = ['A','B','C','D'];
    for (var i=0; i<this.state.ops.length; i++)
      if (this[letters[i]])
        this[letters[i]].current.setlook('normal');
  }

  retry() {
    this.setState({guess:null,guessWrong:null});
    //reset all buttons
    //this.resetOptions();
  }

  pick(val) {
    //this.resetOptions();
    this.setState({guess:val});
  }

  next() {
    this.setState({guess:null,guessWrong:null});
    this.resetOptions();
    this.playControls.current.resetRec();
    this.askQuestion();
  }

  componentDidMount() {
    this.askQuestion();
  }

  askQuestion() {
    //load recording here
    //call server for multiple choice question
    //include level and qtype in request
    //Ex. call level 6 scale_id
    //returns options, right answer, and url for recording
    var dis = this;
    var cb = function(txt) {
      var obj = JSON.parse(txt);
      dis.setState({ops:obj.ops,answer:obj.answer,text:obj.text});
      var rbj = {Rec:obj.recID+'.mp3'};
      dis.playControls.current.setupControls(rbj);
    }
    xhrGET('/multchoice?level='+this.props.level+'&qtype='+this.props.type,cb);
  }

  answerChoices() {
    var ops = this.state.ops;
    var ary = [];
    var letters = ['A','B','C','D'];
    for (var i=0; i<ops.length; i++) {
      ary.push(
        <div className="answerchoice" key={i}>
          <AnswerChoice choice={letters[i]} ref={this[letters[i]]} info={this.state} pick={this.pick}/><div className="multchoice">{ops[i]}</div>
        </div>
      )
    }
    return ary;
  }

  render() {
    return (
      <div className="rmdictation">
        <div className="qtext">{this.state.text}</div>
        <div><PlayControls size="big" ref={this.playControls}/></div>
        <div className="answerchoices">
          {this.answerChoices()}
        </div>
      </div>  
    )
  }
}

// return a random permutation of a range (similar to randperm in Matlab)
function randperm(maxValue){
  // first generate number sequence
  var permArray = new Array(maxValue);
  for(var i = 0; i < maxValue; i++){
      permArray[i] = i;
  }
  // draw out of the number sequence
  for (var i = (maxValue - 1); i >= 0; --i){
      var randPos = Math.floor(i * Math.random());
      var tmpStore = permArray[i];
      permArray[i] = permArray[randPos];
      permArray[randPos] = tmpStore;
  }
  return permArray;
}

class MelodyID extends React.Component {
  constructor(props) {
    super(props);
    this.askQuestion = this.askQuestion.bind(this);
    this.next = this.next.bind(this);
    this.state = {answer:null,guess:null,guessWrong:null};

    this.A = React.createRef();
    this.B = React.createRef();
    this.C = React.createRef();

    this.pick = this.pick.bind(this);
    this.submit = this.submit.bind(this);

    this.playControls = React.createRef();

    this.resetOptions = this.resetOptions.bind(this);
  }

  submit() {
    if (!this.state.guess) {
      this.props.topbarRetry();
      return;
    }
    if (this.state.guess==this.state.answer) {
      this.props.correct(true);
      this.setState({guess:null,guessWrong:null});
      //this.resetOptions();
    } else {
      //incorrect
      this.setState({guessWrong:true});
    }
    this.playControls.current.resetRec();
  }

  resetOptions() {
    this.A.current.setlook('normal');
    this.B.current.setlook('normal');
    this.C.current.setlook('normal');
  }

  retry() {
    this.setState({guess:null,guessWrong:null});
    //reset all buttons
    //this.resetOptions();
  }

  pick(val) {
    //this.resetOptions();
    this.setState({guess:val});
  }

  next() {
    this.setState({guess:null,guessWrong:null});
    this.resetOptions();
    this.playControls.current.resetRec();
    this.askQuestion();
  }

  componentDidMount() {
    this.askQuestion();
  }

  askQuestion() {
    var dis = this;
    var callback = function(txt) {
      var obj = JSON.parse(txt);
      var letters = ['A','B','C'];
      for (var i=0; i<letters.length; i++) {
        dis['MDS'+letters[i]] = new window.melodyDisplayStaff('melodydisplay'+letters[i],document.getElementById('melodydisplay'+letters[i]),obj[letters[i]]);
        dis['MDS'+letters[i]].draw(obj[letters[i]].melody);
      }
      
      var aobj = obj[obj.answer];
      dis.setState({answer:obj.answer});

      var compound = false;
      if (aobj.tsig.indexOf('/8')!=-1) compound = true;

      var rbj = {Rec:aobj.recID+'.mp3'};
      var tsigNum = parseInt(aobj.tsig.split('/')[0]);
      dis.playControls.current.setupControls(rbj,tsigNum,compound);
    }

    xhrGET('/melodyid?level='+this.props.level,callback);
  }

  render() {
    return (
      <div className="rmdictation">
        <div className="qtext">Which <b>melody</b> do you hear?</div>
        <div><PlayDictationControls size="big" ref={this.playControls}/></div>
        <div className="answerchoices">
          <div className="answerchoice">
            <AnswerChoice choice="A" ref={this.A} info={this.state} pick={this.pick}/><div id="melodydisplayA" className="rhythmDisplay"></div>
          </div>
          <div className="answerchoice">
            <AnswerChoice choice="B" ref={this.B} info={this.state} pick={this.pick}/><div id="melodydisplayB" className="rhythmDisplay"></div>
          </div>
          <div className="answerchoice">
            <AnswerChoice choice="C" ref={this.C} info={this.state} pick={this.pick}/><div id="melodydisplayC" className="rhythmDisplay"></div>
          </div>
        </div>
      </div>  
    )
  }
}

class RhythmID extends React.Component {
  constructor(props) {
    super(props);
    this.askQuestion = this.askQuestion.bind(this);
    this.next = this.next.bind(this);
    this.state = {ops:[],answer:null,guess:null,guessWrong:null};

    this.A = React.createRef();
    this.B = React.createRef();
    this.C = React.createRef();
    this.D = React.createRef();

    this.pick = this.pick.bind(this);
    this.submit = this.submit.bind(this);

    this.playControls = React.createRef();

    this.answerChoices = this.answerChoices.bind(this);

    this.resetOptions = this.resetOptions.bind(this);
  }

  submit() {
    if (!this.state.guess) {
      this.props.topbarRetry();
      return;
    }
    if (this.state.guess==this.state.answer) {
      this.props.correct(true);
      this.setState({guess:null,guessWrong:null});
      //this.resetOptions();
    } else {
      //incorrect
      this.setState({guessWrong:true});
    }
    this.playControls.current.resetRec();
  }

  resetOptions() {
    var letters = ['A','B','C','D'];
    for (var i=0; i<this.state.ops.length; i++)
      if (this[letters[i]])
        this[letters[i]].current.setlook('normal');
  }

  retry() {
    this.setState({guess:null,guessWrong:null});
    //reset all buttons
    //this.resetOptions();
  }

  pick(val) {
    //this.resetOptions();
    this.setState({guess:val});
  }

  next() {
    this.setState({guess:null,guessWrong:null});
    this.resetOptions();
    this.playControls.current.resetRec();
    this.askQuestion();
  }

  componentDidMount() {
    this.askQuestion();
  }

  askQuestion() {
    var dis = this;
    var callback = function(txt) {
      var obj = JSON.parse(txt);
      var letters = obj.ops;
      dis.setState({ops:letters,answer:obj.answer});
      for (var i=0; i<letters.length; i++) {
        dis['RDS'+letters[i]] = new window.rhythmDisplayStaff('rhythmdisplay'+letters[i],document.getElementById('rhythmdisplay'+letters[i]),obj[letters[i]]);
        dis['RDS'+letters[i]].draw(obj[letters[i]].rhythms);
      }
      
      var aobj = obj[obj.answer];

      var compound = false;
      if (aobj.tsig.indexOf('/8')!=-1) compound = true;

      var rbj = {Rec:aobj.recID+'.mp3'};
      var tsigNum = parseInt(aobj.tsig.split('/')[0]);
      dis.playControls.current.setupControls(rbj,tsigNum,compound);
    }

    xhrGET('/rhythmid?level='+this.props.level,callback);
  }

  answerChoices() {
    var ops = this.state.ops;
    var ary = [];
    var letters = ['A','B','C','D'];
    for (var i=0; i<ops.length; i++) {
      ary.push(
        <div className="answerchoice" key={i}>
          <AnswerChoice choice={letters[i]} ref={this[letters[i]]} info={this.state} pick={this.pick}/><div id={"rhythmdisplay"+letters[i]} className="rhythmDisplay"></div>
        </div>
      )
    }
    return ary;
  }

  render() {
    return (
      <div className="rmdictation">
        <div className="qtext">Which <b>rhythm</b> do you hear?</div>
        <div><PlayDictationControls size="big" ref={this.playControls}/></div>
        <div className="answerchoices">
          {this.answerChoices()}
        </div>
      </div>  
    )
  }
}

class AnswerChoice extends React.Component {
  constructor(props) {
    super(props);
    this.pick = this.pick.bind(this);
    this.state = {howlook:'normal'};

    this.howlook = this.howlook.bind(this);
    this.setlook = this.setlook.bind(this);
  }

  pick() {
    this.props.pick(this.props.choice);
    this.setlook('selected');
  }

  setlook(val) {
    this.setState({howlook:val});
  }

  howlook() {
    if (this.props.info.guess==null) {
      return (
        <div className="answerletter" onClick={this.pick}>{this.props.choice+'.'}</div>
      )
    }
    if (this.props.info.guess==this.props.choice) {
      if (this.props.info.guessWrong) {
        return (
          <div className="answerwrong">{this.props.choice+'.'}</div>
        ) 
      } else {
        return (
          <div className="answerselected">{this.props.choice+'.'}</div>
        )
      }
    } else {
      if (this.props.info.guessWrong) {
        return (
          <div className="answerletter">{this.props.choice+'.'}</div>
        )
      } else {
        return (
          <div className="answerletter" onClick={this.pick}>{this.props.choice+'.'}</div>
        )
      }
    }
    
  }

  render() {
    return (
      <div>{this.howlook()}</div>
    )
  }
}

class MelodicDictation extends React.Component {
  constructor(props) {
    super(props);
    this.logContents = this.logContents.bind(this);
    this.state = {instructions:'',questions:[],index:0,loaded:false,qid:null,correct:true};

    this.rhythmControls = React.createRef();
    this.melodyControls = React.createRef();

    this.askQuestion = this.askQuestion.bind(this);

    this.submit = this.submit.bind(this);
    this.retry = this.retry.bind(this);
    this.next = this.next.bind(this);
  }

  submit() {
    var dis = this;
    var cb = function(txt) {
      var obj = JSON.parse(txt);
      dis.RS.displayErrors(obj.rhythmerrors);
      dis.MS.displayErrors(obj.melodyerrors);

      if (obj.rhythmerrors.length==0&&obj.melodyerrors.length==0) {
        dis.props.correct(true);
      }
    }
    var obj = {};
    obj.rhythms = this.RS.getInput();
    obj.melody = this.MS.getMelody();
    obj.qid = this.state.qid;
    xhrPOST('/grade',JSON.stringify(obj),cb);
  }

  retry() {
    this.RS.clearErrors();
    this.RS.enable();

    this.MS.clearErrors();
  }

  next() {
    this.rhythmControls.current.resetRec();
    this.melodyControls.current.resetRec();
    this.askQuestion();
  }

  askQuestion() {
    var dis = this;
    var callback = function(txt) {
      console.log(txt);
      var obj = JSON.parse(txt);
      dis.setState({qid:obj.recID});
      var RS = new window.rhythmStaff('rhythmeditor',1000,obj);
      dis.RS = RS;

      var MS = new window.melodyInputStaff('melodyeditor',document.getElementById('melodyeditor'),obj);
      dis.MS = MS;

      var compound = false;
      if (obj.tsig.indexOf('/8')!=-1)
        compound = true;
      
      //save urls
      var rbj = {Rec:obj.recID+'_rhythm.mp3'};
      var tsigNum = parseInt(obj.tsig.split('/')[0]);
      dis.rhythmControls.current.setupControls(rbj,tsigNum,compound);

      var mbj = {Rec:obj.recID+'.mp3'};
      dis.melodyControls.current.setupControls(mbj,tsigNum,compound);
    }

    xhrGET('/dictation?level='+this.props.level,callback);
  }

  //when component mounts, fill in instructions
  componentDidMount() {
    //put instructions
    this.quill = new window.Quill('#directions', {
      modules: {
        toolbar: false
      },
      placeholder: 'Compose an epic...',
      theme: 'snow'  // or 'bubble'
    });
    //will load this from redis later, need page to make instructions -> JSON stringifies quill.getContents() -> then encodeURI that -> save as, ex.: FSMTA_L6.MELODIC_DICTATION.directions [string]
    var str = '%7B%22ops%22:%5B%7B%22insert%22:%22DIRECTIONS:%5CnWrite%20the%20%22%7D,%7B%22attributes%22:%7B%22bold%22:true%7D,%22insert%22:%224%20measures%20of%20rhythm%22%7D,%7B%22insert%22:%22%20on%20the%20single%20line%20staff.%22%7D,%7B%22attributes%22:%7B%22list%22:%22ordered%22%7D,%22insert%22:%22%5Cn%22%7D,%7B%22insert%22:%22Write%20the%20%22%7D,%7B%22attributes%22:%7B%22bold%22:true%7D,%22insert%22:%22melody%22%7D,%7B%22insert%22:%22%20on%20the%20staff%20below,%20using%20the%20same%20rhythm.%22%7D,%7B%22attributes%22:%7B%22list%22:%22ordered%22%7D,%22insert%22:%22%5Cn%22%7D%5D%7D';
    var contents = JSON.parse(decodeURI(str));
    this.quill.setContents(contents);
    this.quill.disable();

    //call to get number of questions
    //set state questions = [id1,id2,etc.]

    //ask question
    this.askQuestion();
  }

  logContents() {
    var contents = this.quill.getContents();
    console.log(encodeURI(JSON.stringify(contents)));
  }

  render() {
    return (
      <div className="rmdictation">
        <div className="directions" id="directions"></div>
        <div className="dictwidg">
          <div className="infoholder">
            <span><PlayDictationControls ref={this.rhythmControls}/></span>
            <span className="infobtn" data-tip data-for="rhythminput"><Info/></span>
            <ReactTooltip id="rhythminput" place="left" effect="solid">
              <b>To input a rhythm:</b>
              <div>- Click the measure</div>
              <div>- Click the rhythm you want to enter</div>
              <b>To remove a rhythm:</b>
              <div>- Click the measure</div>
              <div>- Press Backspace</div>
            </ReactTooltip>
          </div>
          <div id="rhythmeditor" tabIndex="0" className="musiceditor"></div>
        </div>
        <div className="rmspacer"></div>
        <div className="dictwidg">
          <div className="infoholder">
            <span><PlayDictationControls ref={this.melodyControls}/></span>
            <span className="infobtn" data-tip data-for="melinput"><Info/></span>
            <ReactTooltip id="melinput" place="left" effect="solid">
              <b>To input a note:</b>
              <div>- Click a rest to place a note</div>
              <div>- Set the duration with the palette below the staff</div>
              <b>To remove a note:</b>
              <div>- Click a note to remove it</div>
            </ReactTooltip>
          </div>
          <div id="melodyeditor" className="musiceditor"></div>
        </div>
      </div>
    )
  }
}

class RhythmicDictation extends React.Component {
  constructor(props) {
    super(props);
    this.logContents = this.logContents.bind(this);
    this.state = {instructions:'',questions:[],index:0,loaded:false,qid:null,correct:true};

    this.rhythmControls = React.createRef();
    //this.melodyControls = React.createRef();

    this.askQuestion = this.askQuestion.bind(this);

    this.submit = this.submit.bind(this);
    this.retry = this.retry.bind(this);
    this.next = this.next.bind(this);
  }

  submit() {
    var dis = this;
    var cb = function(txt) {
      var obj = JSON.parse(txt);
      dis.RS.displayErrors(obj.rhythmerrors);
      //dis.MS.displayErrors(obj.melodyerrors);

      if (obj.rhythmerrors.length==0) {
        dis.props.correct(true);
      }
    }
    var obj = {};
    obj.rhythms = this.RS.getInput();
    obj.qid = this.state.qid;
    xhrPOST('/graderhythm',JSON.stringify(obj),cb);
  }

  retry() {
    this.RS.clearErrors();
    this.RS.enable();
  }

  next() {
    this.rhythmControls.current.resetRec();
    this.askQuestion();
  }

  askQuestion() {
    var dis = this;
    var callback = function(txt) {
      console.log(txt);
      var obj = JSON.parse(txt);
      dis.setState({qid:obj.recID});
      var RS = new window.rhythmStaff('rhythmeditor',1000,obj);
      dis.RS = RS;

      var compound = false;
      if (obj.tsig.indexOf('/8')!=-1) compound = true;

      //save urls
      var rbj = {Rec:obj.recID+'.mp3'};
      var tsigNum = parseInt(obj.tsig.split('/')[0]);
      dis.rhythmControls.current.setupControls(rbj,tsigNum,compound);
    }

    xhrGET('/dictation?level='+this.props.level,callback);
  }

  //when component mounts, fill in instructions
  componentDidMount() {
    //put instructions
    this.quill = new window.Quill('#directions', {
      modules: {
        toolbar: false
      },
      placeholder: 'Compose an epic...',
      theme: 'snow'  // or 'bubble'
    });
    //will load this from redis later, need page to make instructions -> JSON stringifies quill.getContents() -> then encodeURI that -> save as, ex.: FSMTA_L6.MELODIC_DICTATION.directions [string]
    var str = '%7B%22ops%22:%5B%7B%22insert%22:%22DIRECTIONS:%5CnWrite%20the%20%22%7D,%7B%22attributes%22:%7B%22bold%22:true%7D,%22insert%22:%224%20measures%20of%20rhythm%22%7D,%7B%22insert%22:%22%20on%20the%20single%20line%20staff.%22%7D,%7B%22attributes%22:%7B%22list%22:%22ordered%22%7D,%22insert%22:%22%5Cn%22%7D,%7B%22insert%22:%22Write%20the%20%22%7D,%7B%22attributes%22:%7B%22bold%22:true%7D,%22insert%22:%22melody%22%7D,%7B%22insert%22:%22%20on%20the%20staff%20below,%20using%20the%20same%20rhythm.%22%7D,%7B%22attributes%22:%7B%22list%22:%22ordered%22%7D,%22insert%22:%22%5Cn%22%7D%5D%7D';
    var contents = JSON.parse(decodeURI(str));
    this.quill.setContents(contents);
    this.quill.disable();

    //call to get number of questions
    //set state questions = [id1,id2,etc.]

    //ask question
    this.askQuestion();
  }

  logContents() {
    var contents = this.quill.getContents();
    console.log(encodeURI(JSON.stringify(contents)));
  }

  render() {
    return (
      <div className="rmdictation">
        <div className="directions" id="directions"></div>
        <div className="dictwidg">
          <div className="infoholder">
            <span><PlayDictationControls ref={this.rhythmControls}/></span>
            <span className="infobtn" data-tip data-for="rhythminput"><Info/></span>
            <ReactTooltip id="rhythminput" place="left" effect="solid">
              <b>To input a rhythm:</b>
              <div>- Click the measure</div>
              <div>- Click the rhythm you want to enter</div>
              <b>To remove a rhythm:</b>
              <div>- Click the measure</div>
              <div>- Press Backspace</div>
            </ReactTooltip>
          </div>
          <div id="rhythmeditor" tabIndex="0" className="musiceditor"></div>
        </div>
      </div>
    )
  }
}

class ActionWindow extends React.Component {
  constructor(props) {
    super(props);

    this.renderQtype = this.renderQtype.bind(this);
    this.submit = this.submit.bind(this);
    this.retry = this.retry.bind(this);
    this.next = this.next.bind(this);
    this.question = React.createRef();
    this.state = {correct:null};
    this.correct = this.correct.bind(this);
  }

  submit() {
    this.question.current.submit();
  }

  retry() {
    this.question.current.retry();
  }

  next() {
    this.question.current.next();
  }

  correct(val) {
    this.setState({correct:val});
    var dis = this;
    setTimeout(function(){
      dis.setState({correct:null});
      dis.props.topbarNext();
    },2000)
  }

  workResult() {
    if (this.state.correct==true) {
      return (
        <div className="workresult">
          <Check/>
        </div>
      )
    }
  }

  renderQtype() {
    //console.log(this.props.info.qtype);
    switch(this.props.level) {
      case 'FSMTA_L1':
        switch(this.props.qtype) {
          case 'MELODY_SAMEDIFFERENT':
            return (
              <MultChoiceDoubleRec key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
          case 'RHYTHM_SAMEDIFFERENT':
            return (
              <MultChoiceDoubleRec key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
          case 'MELODY_FASTSLOW':
              return (
                <MultChoiceDoubleRec key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
              )
              break;
          case 'RHYTHM_ID':
              return (
                <RhythmID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
              )
              break;
          default:
            return (
              <MultChoice key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
        }
        break;
      case 'FSMTA_L2':
        switch(this.props.qtype) {
          case 'RHYTHM_ID':
              return (
                <RhythmID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
              )
              break;
          case 'MELODY_ID':
              return (
                <MelodyID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
              )
              break;
          case 'MELODY_RITARDANDO':
              return (
                <MultChoiceDoubleRec key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
              )
              break;
          default:
            return (
              <MultChoice key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
        }
        break;
      case 'FSMTA_L3':
        switch(this.props.qtype) {
          case 'RHYTHMIC_DICTATION':
            return (
              <RhythmicDictation correct={this.correct} ref={this.question} level={this.props.level}/>
            )
            break;
          case 'RHYTHM_ID':
            return (
              <RhythmID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          case 'MELODY_ID':
            return (
              <MelodyID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          default:
            return (
              <MultChoice key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
        }
        break;
      case 'FSMTA_L4':
        switch(this.props.qtype) {
          case 'RHYTHMIC_DICTATION':
            return (
              <RhythmicDictation correct={this.correct} ref={this.question} level={this.props.level}/>
            )
            break;
          case 'RHYTHM_ID':
            return (
              <RhythmID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          case 'MELODY_ID':
            return (
              <MelodyID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          default:
            return (
              <MultChoice key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
        }
        break;
      case 'FSMTA_L5':
        switch(this.props.qtype) {
          case 'RHYTHMIC_DICTATION':
            return (
              <RhythmicDictation correct={this.correct} ref={this.question} level={this.props.level}/>
            )
            break;
          case 'RHYTHM_ID':
            return (
              <RhythmID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          case 'MELODY_ID':
            return (
              <MelodyID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          case 'CHORD_PROGRESSION_ID':
            return (
              <MultChoiceTripleRec key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
          default:
            return (
              <MultChoice key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
        }
        break;
      case 'FSMTA_L6':
        switch(this.props.qtype) {
          case 'MELODIC_DICTATION':
            return (
              <MelodicDictation correct={this.correct} ref={this.question} level={this.props.level}/>
            )
            break;
          case 'RHYTHM_ID':
            return (
              <RhythmID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          case 'MELODY_ID':
            return (
              <MelodyID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          default:
            return (
              <MultChoice key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
        }
        break;
      case 'FSMTA_L7':
        switch(this.props.qtype) {
          case 'MELODIC_DICTATION':
            return (
              <MelodicDictation correct={this.correct} ref={this.question} level={this.props.level}/>
            )
            break;
          case 'RHYTHM_ID':
            return (
              <RhythmID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          case 'MELODY_ID':
            return (
              <MelodyID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          case 'CHORD_PROGRESSION_ID':
            return (
              <MultChoiceTripleRec key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
          default:
            return (
              <MultChoice key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
        }
        break;
      case 'FSMTA_L8':
        switch(this.props.qtype) {
          case 'MELODIC_DICTATION':
            return (
              <MelodicDictation correct={this.correct} ref={this.question} level={this.props.level}/>
            )
            break;
          case 'MELODY_ID':
            return (
              <MelodyID correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level}/>
            )
            break;
          case 'CHORD_PROGRESSION_ID':
            return (
              <MultChoiceTripleRec key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
          default:
            return (
              <MultChoice key={this.props.qtype} correct={this.correct} ref={this.question} topbarRetry={this.props.topbarRetry} level={this.props.level} type={this.props.qtype}/>
            )
            break;
        }
        break;
    }
  }
  //load instructions from database, use Quill.js to display
  render() {
    return (
      <div>
        {this.workResult()}
        {this.renderQtype()}
      </div>
    )
  }
}

class Foot extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="foot">
        <a href="https://www.liontheory.com"><img className="luger" src={"https://www.liontheory.com/img/logo.png"}/></a>
        <div>Copyright © Lion Theory, LLC. All Rights Reserved.</div>
      </div>
    )
  }
}

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {level:this.props.level,loaded:false};
    this.stateHandler = this.stateHandler.bind(this);
    this.readyDisplay = this.readyDisplay.bind(this);

    this.firstQ = this.firstQ.bind(this);
    this.interactive = React.createRef();
  }

  stateHandler(val,st) {
    var obj = {};
    obj[val] = st;
    this.setState(obj);
    if (val=='level')
      this.interactive.current.setQ(this.firstQ(st));
  }

  firstQ(swa) {
    var sw = swa ? swa : this.state.level;
    switch(sw) {
      case 'FSMTA_L1':
        return 'RHYTHM_ID';
        break;
      case 'FSMTA_L2':
        return 'RHYTHM_ID';
        break;
      case 'FSMTA_L3':
        return 'RHYTHMIC_DICTATION';
        break;
      case 'FSMTA_L4':
        return 'RHYTHMIC_DICTATION';
        break;
      case 'FSMTA_L5':
        return 'RHYTHMIC_DICTATION';
        break;
      case 'FSMTA_L6':
        return 'MELODIC_DICTATION';
        break;
      case 'FSMTA_L7':
        return 'MELODIC_DICTATION';
        break;
      case 'FSMTA_L8':
        return 'MELODIC_DICTATION';
        break;
    }
  }

  async componentDidMount() {
    //get last level, last qtype?

    //load all my shit here
    //need quill, need my theory lib
    var loader = new ScriptLoader({
        src: 'www.liontheory.com/js/eartrainer/quill.min.js',
        global: 'QL',
    })
    
    await loader.load()

    loader = new ScriptLoader({
      src: 'www.liontheory.com/js/eartrainer/snap.js',
      global: 'SN',
    })

    await loader.load()

    loader = new ScriptLoader({
      src: 'www.liontheory.com/js/eartrainer/howler.min.js',
      global: 'HW',
    })

    await loader.load()

    loader = new ScriptLoader({
      src: 'www.liontheory.com/js/eartrainer/musiclib.js',
      global: 'ML',
    })

    await loader.load()

    this.setState({loaded:true});
  }

  readyDisplay() {
    if (this.state.loaded) {
      return (<div>
        <Interactive ref={this.interactive} level={this.state.level} qtype={this.firstQ()} bubbleState={this.stateHandler}/>
        <Foot/>
      </div>)
    } else {
      return (
        <div>Please wait...</div>
      )
    }
  }

  render() {
    return (
      <div className="mainContent">
        {this.readyDisplay()}
      </div>
    )
  }
}

class Interactive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {qtype:this.props.qtype};
    this.actionwindow = React.createRef();
    this.submit = this.submit.bind(this);
    this.retry = this.retry.bind(this);
    this.next = this.next.bind(this);
    this.topbar = React.createRef();
    this.topbarNext = this.topbarNext.bind(this);
    this.topbarRetry = this.topbarRetry.bind(this);

    this.stateHandler = this.stateHandler.bind(this);
    this.setQ = this.setQ.bind(this);
  }

  setQ(val) {
    this.setState({qtype:val});
  }

  stateHandler(val,st) {
    var obj = {};
    obj[val] = st;
    this.setState(obj);
  }

  next() {
    this.actionwindow.current.next();
  }

  submit() {
    this.actionwindow.current.submit();
  }

  retry() {
    this.actionwindow.current.retry();
  }

  topbarNext() {
    this.topbar.current.next();
  }

  topbarRetry() {
    this.topbar.current.retry();
  }

  render() {
    return (
      <div>
        <TopBar ref={this.topbar} next={this.next} submit={this.submit} retry={this.retry} bubbleState={this.props.bubbleState} qHandler={this.stateHandler} qtype={this.state.qtype} level={this.props.level} info={this.state}/>
        <ActionWindow ref={this.actionwindow} topbarNext={this.topbarNext} topbarRetry={this.topbarRetry} bubbleState={this.stateHandler} qtype={this.state.qtype} level={this.props.level}/>
      </div>
    )
  }
}

class ScriptLoader {
  constructor (options) {
    const { src, global, protocol = document.location.protocol } = options
    this.src = src
    this.global = global
    this.protocol = protocol
    this.isLoaded = false
  }

  loadScript () {
    return new Promise((resolve, reject) => {
      // Create script element and set attributes
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = `${this.protocol}//${this.src}`

      // Append the script to the DOM
      const el = document.getElementsByTagName('script')[0]
      el.parentNode.insertBefore(script, el)

      // Resolve the promise once the script is loaded
      script.addEventListener('load', () => {
        this.isLoaded = true
        resolve(script)
      })

      // Catch any errors while loading the script
      script.addEventListener('error', () => {
        reject(new Error(`${this.src} failed to load.`))
      })
    })
  }

  load () {
    return new Promise(async (resolve, reject) => {
      if (!this.isLoaded) {
        try {
          await this.loadScript()
          resolve(window[this.global])
        } catch (e) {
          reject(e)
        }
      } else {
        resolve(window[this.global])
      }
    })
  }
}

//load previous level, or just load levels teacher wants
function App() {
  return (
    <div className="App">
      <MainPage level="FSMTA_L1"/>
    </div>
  );
}

//XHR SHIT
function xhrGET(url, callback, errHandler) {
	var xhr = new XMLHttpRequest();
	xhr.open("GET", url, true);
	xhr.onreadystatechange = function() {
		if (xhr.readyState == 4) {
			if (xhr.status == 200) {
				if (!callback) console.log(xhr.responseText)
				  else callback(xhr.responseText)
			} else if (xhr.status == 404) {
				console.log('hi')
				if (errHandler) errHandler(xhr)
			}
		}
	}
	xhr.send();
}

function xhrPOST(url, txt, callback) {
	var xhr = new XMLHttpRequest();
	xhr.open("POST", url, true);
	xhr.onreadystatechange = function() {
		if (xhr.readyState == 4) {
			if (xhr.status != 200)  console.log('xhrPOST err: ' + url + ' status:' + xhr.status)
			  else 	if (callback) callback(xhr.responseText)
		}
	}	
	xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
	xhr.send(txt);
}

export default App;